import TYPES from '@/types';
import Vue from 'vue';

import { v4 } from 'uuid';

// Application
import UpdateInvestorProfileCommand
  from '@/modules/flagship/investor-profile/investor-profile/application/commands/update-investor-profile-command';

// Domain
import {
  InvestorProfileStateManager,
} from '@/modules/flagship/investor-profile/investor-profile/domain/state/investor-profile-state-manager';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class RetirementFundVideoViewModel {
  @Inject(TYPES.INVESTOR_PROFILE_STATE_MANAGER)
  readonly investor_profile_state_manager!: InvestorProfileStateManager;

  @Inject(TYPES.UPDATE_INVESTOR_PROFILE_COMMAND)
  private readonly update_investor_profile_command!: UpdateInvestorProfileCommand;

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  private readonly view!: Vue;

  readonly i18n_namespace = 'components.flagship.flagship-goals.create-goal.retirement-fund.video';

  investor_profile_state = this.investor_profile_state_manager.state;

  video_title = '';

  video_src_webm = 'https://dv1k4uibauddz.cloudfront.net/proyeccion.webm';

  video_src_mp4 = 'https://dv1k4uibauddz.cloudfront.net/fproyeccion.mp4';

  options = [
    { value: true, label: 'Si' },
    { value: false, label: 'No' },
  ];

  retirement_fund_knowledge = false;

  show_projection = false;

  is_loading = false;

  retirement_investor_goal_id = v4();

  public constructor(view: Vue) {
    this.view = view;
    this.video_title = this.translate('title');
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  toggleProjection = () => {
    this.show_projection = !this.show_projection;
  }

  updateInformation = async () => {
    this.investor_profile_state.investor_profile.has_emergency_fund = this.investor_profile_state
      .investor_profile.emergency_fund_saved !== '';
    const patch_payload = {
      id: this.investor_profile_state.investor_profile.id,
      emergency_fund_saved: this.investor_profile_state.investor_profile.emergency_fund_saved,
      has_emergency_fund: this.investor_profile_state.investor_profile.has_emergency_fund,
    };
    await this.update_investor_profile_command.execute(patch_payload);
  }

  nextStep = async () => {
    try {
      this.is_loading = true;
      await this.updateInformation();
      this.view.$emit('nextStep');
    } catch {
      this.messageNotifier.showErrorNotification(this.translate('errors.update_profile'));
    } finally {
      this.is_loading = false;
    }
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }
}
